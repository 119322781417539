import React, { useState } from "react";
import './faqs.css';

const Faqs = () => {
  const [selectedFaq, setSelectedFaq] = useState(1);

  const faqs = [
    { 
      id: 1, 
      question: "What is the main purpose of this app?", 
      answer: "This app is designed to help users find and offer rides. You can either search for available rides to join or offer a ride if you have available seats in your vehicle." 
    },
    { 
      id: 2, 
      question: "How do I create an account?", 
      answer: "You can create an account by signing up with your email and setting a password. If you prefer, you can also sign up using your Google account." 
    },
    { 
      id: 3, 
      question: "How do I find a ride?", 
      answer: "To find a ride, go to the 'Ride Finder' section, select your departure and arrival cities, and optionally specify a route and estimated departure time. Then, click 'Find a Ride' to see available options." 
    },
    { 
      id: 4, 
      question: "How do I offer a ride?", 
      answer: "To offer a ride, go to the 'Ride Poster' section, fill in the details such as departure and arrival cities, route, estimated departure time, total seats available, and fare. Then, click 'Offer Ride' to post your ride." 
    },
    { 
      id: 5, 
      question: "How can I update my profile?", 
      answer: "You can update your profile information, such as phone number and gender, by going to the profile section and editing the relevant fields. Make sure to save the changes to update your profile." 
    },
    { 
      id: 6, 
      question: "How can I reset my password?", 
      answer: "If you forget your password, you can reset it by clicking on the 'Forgot Password' link on the login page. Follow the instructions to reset your password via email." 
    },
    { 
      id: 7, 
      question: "How do I request a ride if I can't find one that suits my needs?", 
      answer: "If you can't find a suitable ride, you can request a ride by filling out the 'Request Ride' form in the 'Ride Finder' section. Provide your departure and arrival cities, route, and estimated departure time, and click 'Request Ride.'" 
    },
    { 
      id: 8, 
      question: "How to view the details of a ride?", 
      answer: "To view the details of a specific ride, click on the ride from the list of available rides. You will be redirected to a page with detailed information about the ride, including the departure and arrival points, route, and the driver’s contact information." 
    },
    { 
      id: 9, 
      question: "Is my personal information secure on this app?", 
      answer: "Yes, your personal information is secure. The app uses encryption and other security measures to protect your data. Your password is hashed and not stored in plain text." 
    },
    { 
      id: 10, 
      question: "How do I contact the support team if I have an issue?", 
      answer: "If you have any issues or need assistance, you can contact the support team by clicking on the 'Contact Us' link on the website or app. Provide your details and the nature of your issue, and the support team will get back to you as soon as possible." 
    },
  ];

  const handleFaqClick = (id) => {
    setSelectedFaq(selectedFaq === id ? null : id);
  };

  return (
    <div className="gpt3__faqs section__padding gradient__bg"> 
      <h1 className="gradient__text">FAQs</h1>

      <div className="gpt3__faqs-container">
      {faqs.map((faq) => (
        <div key={faq.id} className="gpt3__faqs-container__item" onClick={() => handleFaqClick(faq.id)}>
          <h2>{faq.question}</h2>
          {selectedFaq === faq.id && <p>{faq.answer}</p>}
        </div>
      ))}
    </div>
    </div>
  );
};

export default Faqs;