import React, { useState } from "react";
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/sastiride-logo-white.svg';
import './navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faEdit, faHome, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const Navbar = ( {isLoggedIn, onLogout } ) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();
  
  const onButtonClick = async () => {    
    try {
      await axios.post('api/v1/logout');
      onLogout();
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      if (error.response) {
        //console.log(error.response.data.message);
      } else {
        //console.log('An error occurred on Logout. Please try again.');
      }
    }  
  };  

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <Link to="/"><img src={logo} alt="logo" /></Link>
        </div>
        <div className="gpt3__navbar-links_container">
        {isLoggedIn && <Link to="/feed"><p><FontAwesomeIcon icon={faHome}></FontAwesomeIcon>Feed</p></Link>}
        {isLoggedIn && <Link to="/publish"><p><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>Publish</p></Link>}
        {isLoggedIn && <Link to="/listing"><p><FontAwesomeIcon icon={faCar}></FontAwesomeIcon>My Ride</p></Link>}
        {isLoggedIn && <Link to="/profile"><p><FontAwesomeIcon icon={faUser}></FontAwesomeIcon>Profile</p></Link>}
        <Link to="/faqs"><p><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>FAQs</p></Link>
          {/*
          <p><a href="#possibility">Open AI</a></p>
          <p><a href="#features">Case Studies</a></p>
          <p><a href="#blog">Library</a></p>
          */}
        </div>      
      </div>
      {isLoggedIn
      ? <div className="gpt3__navbar-sign">
          <button type="button" onClick={onButtonClick}><Link to="/login">Log out</Link></button>
        </div>
      : <div className="gpt3__navbar-sign">
          <p><Link to="/login">Sign in</Link></p>          
        </div>
      }
      <div className="gpt3__navbar-menu">
        { toggleMenu
          ? <RiCloseLine color="#fff" size={22} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={18} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center" onClick={() => setToggleMenu(!toggleMenu)}>
          <div className="gpt3__navbar-menu_container-links">
            {isLoggedIn && <Link to="/feed"><p><FontAwesomeIcon icon={faHome}></FontAwesomeIcon>Feed</p></Link>}
            {isLoggedIn && <Link to="/publish"><p><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>Publish</p></Link>}
            {isLoggedIn && <Link to="/listing"><p><FontAwesomeIcon icon={faCar}></FontAwesomeIcon>My Ride</p></Link>}
            {isLoggedIn && <Link to="/profile"><p><FontAwesomeIcon icon={faUser}></FontAwesomeIcon>Profile</p></Link>}
            <Link to="/faqs"><p><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>FAQs</p></Link>
            {/*
            <p><a href="#possibility">Open AI</a></p>
            <p><a href="#features">Case Studies</a></p>
            <p><a href="#blog">Library</a></p>
            */}
          </div>
          {isLoggedIn
          ? <div className="gpt3__navbar-sign">
              <button type="button" onClick={onButtonClick}><Link to="/login">Log out</Link></button>
            </div>
          : <div className="gpt3__navbar-sign">
              <p><Link to="/login">Sign in</Link></p>              
            </div>
          }
        </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;