import React from "react";
import './profile.css';
import { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";

const Profile = ( { isLoggedIn, loginUser, onLogout } ) => {
  const { profileId } = useParams();
  //const [formData, setFormData] = useState({departure: "", arrival: "", estimatedDate: "", totalSeats: "" });
  const [profileUser, setProfileUser] = useState({});
  const [profileFormData, setProfileFormData] = useState({password: "", confirmPassword: "", phoneNumber: loginUser.phoneNumber || '', gender: loginUser.gender || ''});
  const [error, setError] = useState("");
  // const [passwordError, setPasswordError] = useState('');
  const [isProfileEditMode, setIsProfileEditMode] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    const getProfile = async () => {
      try {
        if (profileId && profileId !== loginUser._id) {
          const response = await axios.get(`/api/v1/user/${profileId}`);

          setProfileUser(response.data.user);
        } else {
          setProfileUser(loginUser);
        }        
      } catch (error) {
          if (error.response && error.response.status === 401) {
            //console.log('Session expired, redirecting to login...');
            onLogout();
            navigate('/login'); // Redirect user to login
          } 
      }
  };

    getProfile();
  }, [profileId, loginUser, onLogout, navigate]);

  const handleUpdateProfile = async (event) => {
    event.preventDefault();
    setError(''); // Reset error message
    /*
    setPasswordError(''); // Reset error message
    
    // Frontend validation
    if (!profileFormData.password || !profileFormData.confirmPassword || !profileFormData.phoneNumber) {
      setError('⚠️ All fields are required!.');
      return;
    }

    // Frontend validation
    if (profileFormData.password.length < 7) {
      setPasswordError('⚠️ Password must be atleast 8 characters long');
      return;
    }
    // Frontend validation
    if (profileFormData.password !== profileFormData.confirmPassword) {
      setPasswordError('⚠️ Password did not match');
      return;
    }
    */

    try {
        await axios.post(`/api/v1/user/${profileUser._id}?_method=PUT`, profileFormData);
        
        setIsProfileEditMode(false);
    } catch (error) {
        if (error.response) {
          setError(error.response.data.message);
        } else {
          setError('⚠️ An error occurred on Profile update. Please try again');
        }
    } finally {
      onLogout();
      navigate('/login');
    }
  };

  const handleProfileChange = (event) => {
    setError(''); // Reset error message
    // setPasswordError(''); // Reset error message

    const { name, value } = event.target;
    setProfileFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleEditProfileClick = () => {
    setIsProfileEditMode(true);
  };

  const handleCancelEditProfileClick = () => {
    setIsProfileEditMode(false);
  };
  
  if (!isLoggedIn) {
    navigate('/');
    return;
  }

  if (isProfileEditMode) {
    return(
      <div className="gpt3__feed section__padding gradient__bg">
        <div className="profile__form">
          <div className="profile__form-content">
            <h1 className="gradient__text">Update Profile</h1>          
            
            <div className="login__form-content__input">
              <form onSubmit={handleUpdateProfile} >
                {/*
                <label htmlFor="password">New Password</label>
                <input type="password" id="password" name="password" value={profileFormData.password} onChange={handleProfileChange} placeholder="Password"/>
                {passwordError && <label htmlFor="password" className="error">{passwordError}</label>}
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input type="password" id="confirmPassword" name="confirmPassword" value={profileFormData.confirmPassword} onChange={handleProfileChange} placeholder="Re-enter password"/>
                {passwordError && <label htmlFor="confirmPassword" className="error">{passwordError}</label>}
                */}
                <label htmlFor="phoneNumber">Phone Number</label>
                <input type="tel" id="phoneNumber" name="phoneNumber" value={profileFormData.phoneNumber} onChange={handleProfileChange} placeholder={profileUser.phoneNumber}/>
                
                <label htmlFor="gender">Select Gender</label>
                <select id="gender" name="gender" value={profileFormData.gender} onChange={handleProfileChange}>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="custom">Custom</option>
                </select>

                {error && <p>{error}</p>}

                <button className="green-btn" type="submit">Update</button>
                <button onClick={handleCancelEditProfileClick}>Cancel</button>
              </form>
            </div> 
          </div>        
        </div>
      </div>
    );
  }

  return (
    <div className="gpt3__feed section__padding gradient__bg">
      <div className="profile__form">
        <div className="profile__form-content">
          <h1 className="gradient__text">User Profile</h1>

          {/*
          <div className="profile__form-content__wrapper">
            <div className="profile__form-content__wrapper-image">
              <img src={profileUser.avatar} alt="" />
            </div>

            <div className="profile__form-content__wrapper-data">
              <p>{profileUser.userName}</p>
              <p className="lowercase-letter">{profileUser.email}</p>
              <p>{profileUser.phoneNumber || "Nil"}</p>
              <p>{profileUser.role}</p>
              <p>{profileUser.gender}</p>
              <p>{new Date(profileUser.createdAt).toLocaleDateString()}</p>
            </div>
          </div>
          */}

          <div className="profile__form-content__field">
            {/*<img src={profileUser.avatar} alt="" />*/}
            <div className="profile__form-content__field-div">
              <h3>Name: </h3><p>{profileUser.userName}</p>
            </div>
            {profileUser._id === loginUser._id && 
              <div className="profile__form-content__field-div">
                <h3>Email: </h3><p className="lowercase-letter">{profileUser.email}</p>
              </div>
            }
            <div className="profile__form-content__field-div">
              <h3>Phone Number: </h3><p>{profileUser.phoneNumber || "Nil"}</p>
            </div>
            {profileUser._id === loginUser._id && 
              <div className="profile__form-content__field-div">
                <h3>Role: </h3><p>{profileUser.role}</p>
              </div>
            }
            <div className="profile__form-content__field-div">
              <h3>Gender: </h3><p>{profileUser.gender}</p>
            </div>
            <div className="profile__form-content__field-div">
              <h3>Joined Date: </h3><p>{new Date(profileUser.createdAt).toLocaleDateString()}</p>
            </div>
          </div>

          {profileUser._id === loginUser._id && <Link to=""><button onClick={handleEditProfileClick}>Edit Profile</button></Link>}
          {profileUser._id === loginUser._id && loginUser.role === 'admin' && <Link to="/admin"><button>Manage Users</button></Link>}
          {profileUser._id !== loginUser._id && loginUser.role === 'admin' && <Link to={`/listing/${profileUser._id}`}><button>Users Listing</button></Link>}
        </div>
      </div>
    </div>
  );
};

export default Profile;