import React from "react";
import { default as Header } from "../header/Header";
/*
import { default as WhatGPT3 } from "../whatGPT3/WhatGPT3";
import { default as  CTA } from "../../components/cta/CTA";
*/
const Home = ( { isLoggedIn }) => {
  return (
    <div>
      <Header isLoggedIn={ isLoggedIn }></Header>
      {/*<Brand></Brand>*/}
      {/*<WhatGPT3></WhatGPT3>
      <Features></Features>
      <Possibility></Possibility>
      <CTA></CTA>*/}
      {/*<Blog></Blog>*/}
    </div>
  );
};

export default Home;