import React from "react";
import './listing.css';
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPlaneArrival, faPlaneDeparture, faRoute } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const Listing = ( { isLoggedIn, loginUser, onLogout } ) => {
  const { profileId } = useParams();
  //const [formData, setFormData] = useState({departure: "", arrival: "", estimatedDate: "", totalSeats: "" });
  const [offeredRides, setOfferedRides] = useState([]);
  const [bookedRides, setBookedRides] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [isBookingMode, setIsBookingMode] = useState(false);
  // const [passwordError, setPasswordError] = useState('');
  const [offeredRideError, setOfferedRideError] = useState("");
  const [bookedRideError, setBookedRideError] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    const getOfferedRide = async () => {
      try {
        let response; 
        if (profileId && profileId !== loginUser._id) {
          response = await axios.get(`/api/v1/ride/${profileId}`);
        } else {
          response = await axios.get(`/api/v1/ride/own`);
        }

        // Use optional chaining and provide fallback values
        const temp_rides = response.data.rides || [];
        
        setOfferedRides(temp_rides);

        // Clear the error if there are rides
        if (temp_rides.length > 0) {
          setOfferedRideError(null);
        } else {
          setOfferedRideError('⚠️ No rides posted yet.');
        }
      } catch (error) {
          if (error.response && error.response.status === 401) {
            //console.log('Session expired, redirecting to login...');
            onLogout();
            navigate('/login'); // Redirect user to login
          } else if (error.response) {
            //console.log(error.response.data.message);
            setOfferedRideError(error.response.data.message);
          } else {
            //console.log('⚠️ An error occurred while fetching rides');
            setOfferedRideError('⚠️ An error occurred while fetching rides');
          }    
      } finally {
        setLoading(false); // Stop loading after the request completes
      }
  };

    getOfferedRide();
  }, [profileId, loginUser, onLogout, navigate]);

  useEffect(() => {
    const getBookedRide = async () => {
      try {
        let response; 
        if (profileId && profileId !== loginUser._id) {
          response = await axios.get(`/api/v1/ride/bookedRides/${profileId}`);
        } else {
          response = await axios.get(`/api/v1/ride/bookedRides/${loginUser._id}`);
        }

        // Use optional chaining and provide fallback values
        const temp_rides = response.data.bookedRides || [];
        
        setBookedRides(temp_rides);

        // Clear the error if there are rides
        if (temp_rides.length > 0) {
          setBookedRideError(null);
        } else {
          setBookedRideError('⚠️ No rides booked yet.');
        }
      } catch (error) {
          if (error.response && error.response.status === 401) {
            //console.log('Session expired, redirecting to login...');
            onLogout();
            navigate('/login'); // Redirect user to login
          } else if (error.response) {
            //console.log(error.response.data.message);
            setBookedRideError(error.response.data.message);
          } else {
            //console.log('⚠️ An error occurred while fetching bookings');
            setBookedRideError('⚠️ An error occurred while fetching bookings');
          }    
      } finally {
        setLoading(false); // Stop loading after the request completes
      }
  };

    getBookedRide();
  }, [profileId, loginUser, onLogout, navigate]);

  const handleBookingClick = () => {
    setIsBookingMode(true); // Set the state to true when the user clicks Sign Up
  };

  const handleOfferedClick = () => {
    setIsBookingMode(false); // Set the state to false when the user clicks Login
  };
  
  if (!isLoggedIn) {
    navigate('/');
    return;
  }

  // Show a loading indicator while data is being fetched
  if (loading) {
    return (
      <div className="listing__form section__padding gradient__bg">
        <div className="listing__ride-container"> 
          <h1 className="gradient__text">Loading Rides ...</h1>
        </div>
      </div>
    );
  }

  const handleGetSingleRide = (rideId) => {
    //console.log(`handleGetSingleRide triggered with rideId: ${rideId}`);
    
    navigate(`/ride/${rideId}`);
  };

  return (
    <div className="listing__form section__padding gradient__bg">
      <div className="listing__ride-container">
        <h1 className="gradient__text">{/*`${profileUser.userName}'s Rides` ||*/ "User Rides"}</h1>
        <div className="listing__ride-container__slide-controls">
          <input type="radio" name="slide" id="offer" checked={!isBookingMode} onChange={handleOfferedClick} />
          <input type="radio" name="slide" id="booking" checked={isBookingMode} onChange={handleBookingClick} />
          <label htmlFor="offer" className={`slide`} onClick={handleOfferedClick}> Offered </label>
          <label htmlFor="booking" className={`slide`} onClick={handleBookingClick}> Booked </label>
          <div className={`slider-tab ${isBookingMode ? 'shift-right-slider' : ''}`}></div>
        </div>

        <div className="listing__ride-container__forms">        
          <div className={`listing__ride-container__wrapper ${isBookingMode ? 'shift-right' : ''}`}>
            {/* Offered Rides */}
            <div className={`listing__ride-container__wrapper-slider`}>                
              <div className={`listing__ride-container__wrapper-slider__content ${isBookingMode ? 'hide' : ''}`}>
              {offeredRideError && <p>{offeredRideError}</p>}
              {offeredRides.length > 0 &&
                (offeredRides.map((element, idx) => {
                  const { _id, arrival, departure, estimatedDate, route } = element;
                  
                  let date = new Date(estimatedDate);
                  const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

                  if (Math.abs(date - Date.now()) < oneDayInMs) {
                    date = date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}); // Within 24 hours, show time
                  } else {
                    date = date.toLocaleDateString(); // More than 24 hours away, show date
                  }
                  return (
                    <div onClick={() => handleGetSingleRide(_id)} className={`listing__ride-container__wrapper-slider__content-post`}>
                      <h2><FontAwesomeIcon icon={faPlaneDeparture} /> {departure} </h2>
                      <h2><FontAwesomeIcon icon={faPlaneArrival} /> {arrival}</h2>
                      <p><FontAwesomeIcon icon={faRoute} /> {route.length > 18 ? `${route.substring(0, 15)}...` : route}</p>
                      <p>
                        <FontAwesomeIcon icon={faCalendar} />
                        {date}
                        {/*new Date(estimatedDate).toLocaleDateString()}
                        <span>{new Date(estimatedDate).toLocaleTimeString()</span>*/}
                      </p>
                      {/*<Field heading="To" text={arrival}></Field>
                      <Field heading="From" text={departure}></Field>
                      <Field heading="Date" text={new Date(estimatedDate).toLocaleDateString()}></Field>*/}
                    </div>
                  );
                }))
              }      
              </div>
            </div>
            
            {/* Booked Rides */}
            <div className="listing__ride-container__wrapper-slider">
              <div className={`listing__ride-container__wrapper-slider__content  ${isBookingMode ? '' : 'hide'}`}>
              {bookedRideError && <p>{bookedRideError}</p>}
              {bookedRides.length > 0 &&
                (bookedRides.map((element, idx) => {
                  const { _id, arrival, departure, estimatedDate, route } = element;
                  
                  let date = new Date(estimatedDate);
                  const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

                  if (Math.abs(date - Date.now()) < oneDayInMs) {
                    date = date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}); // Within 24 hours, show time
                  } else {
                    date = date.toLocaleDateString(); // More than 24 hours away, show date
                  }

                  return (
                    <div onClick={() => handleGetSingleRide(_id)} className={`listing__ride-container__wrapper-slider__content-post`}>
                      <h2><FontAwesomeIcon icon={faPlaneDeparture} /> {departure} </h2>
                      <h2><FontAwesomeIcon icon={faPlaneArrival} /> {arrival}</h2>
                      <p><FontAwesomeIcon icon={faRoute} /> {route.length > 18 ? `${route.substring(0, 15)}...` : route}</p>
                      <p>
                        <FontAwesomeIcon icon={faCalendar} />
                        {date}
                        {/*new Date(estimatedDate).toLocaleDateString()}
                        <span>{new Date(estimatedDate).toLocaleTimeString()</span>*/}
                      </p>
                      {/*<Field heading="To" text={arrival}></Field>
                      <Field heading="From" text={departure}></Field>
                      <Field heading="Date" text={new Date(estimatedDate).toLocaleDateString()}></Field>*/}
                    </div>
                  );
                }))
              }      
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>
  );
};

export default Listing;