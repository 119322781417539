import React from "react";
import './feed.css';
import { useCallback, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPlaneArrival, faPlaneDeparture, faRoute } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const Feed = ( { isLoggedIn, loginUser, onLogout } ) => {
  /*const [cities, setCities] = useState(["New York", "Paris", "Tokyo", "London", "Sydney"]);*/
  const [finderFormData, setFinderFormData] = useState({departure: "", arrival: "", route:"", estimatedDate: formatDate(new Date()) });
  const [feedDetails, setFeedDetails] = useState([]);
  const [finderError, setFinderError] = useState("");
  const [isRideOnly, setIsRideOnly] = useState(false);
  const navigate = useNavigate();

  const getAllRides = useCallback (async () => {
    try {
      if (isRideOnly) {
        const response = await axios.get('/api/v1/ride/all');
  
        setFeedDetails(response.data.rides);
      } else {
        const response = await axios.get('/api/v1/ride/requests/all');

        setFeedDetails(response.data.ridesAndRequests);       
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else if (error.response) {
        //console.log(error.response.data.message);
        setFinderError(error.response.data.message);
        setFeedDetails([]); 
      } else {
        setFinderError(error.response.data.message);
        setFeedDetails([]); 
      }
    }
  }, [isRideOnly, onLogout, navigate]);

  useEffect(() => {
    let intervalId;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !intervalId) {
        intervalId = setInterval(getAllRides, 30000); // Poll every 30 seconds when tab is active
      } else if (document.visibilityState !== 'visible' && intervalId) {
        clearInterval(intervalId); // Pause polling when tab is inactive
        intervalId = null;
      }
    };
  
    // Fetch immediately when `isRideOnly` changes or when component mounts
    getAllRides();

    if (document.visibilityState === 'visible') {
      intervalId = setInterval(getAllRides, 30000);
    }
    
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isRideOnly, getAllRides]);
  /*
  useEffect(()=> {
    const defaultCities = /*loginUser.country !== "Pakistan" 
      ? ["New York", "Paris", "Tokyo", "London", "Sydney"] 
      : ["Islamabad", "Rawalpindi", "Kamra", "Lahore", "Multan", "Peshawar"];
      
    setCities(defaultCities);
    setFinderFormData((prevFormData) => ({ ...prevFormData, departure: "", arrival: "" }));
  }, [loginUser]);*/
  
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);
  
  // Helper function to format the date
  function formatDate(date) {
    const pad = (num) => String(num).padStart(2, '0');
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours()+1)}:${pad(date.getMinutes())}`;
  }

  const handleFinderChange = (event) => {
    setIsRideOnly(false);
    setFinderError("");

    const { name, value } = event.target;
    setFinderFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleFinderSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.get('/api/v1/ride/filterRide/', {
        params: {
          departure: finderFormData.departure,
          arrival: finderFormData.arrival,
          /*route: finderFormData.route,
          estimatedDate: finderFormData.estimatedDate,*/
        }
      });

      setFeedDetails(response.data.filteredRides);
      
    } catch (error) {
      //console.log(error.response.status);
      if (error.response && error.response.status === 401) {
        //console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else {
        setFinderError(error.response.data.message);
      }
    }
  };
  /*
  const handleClearFilter = async (event) => {
    event.preventDefault();

    setFinderFormData({departure: "", arrival: "", estimatedDate: formatDate(new Date()) });
    setFinderError("");

    getAllRides();
  }
  */
  const handleGetSingleRide = (rideId) => {
   //console.log(`handleGetSingleRide triggered with rideId: ${rideId}`);
   
   navigate(`/ride/${rideId}`);
  };
  
  return (
    <div className="gpt3__feed section__padding gradient__bg">
      <div className="feed__content">
        <div className="feed__content-heading">
          <h1 className={`gradient__text`}>{`${isRideOnly ? 'Recent Rides!' : 'Rides and Requests'}`}</h1>
          <p>Explore all available Offers and Requests. Select filters to find specific ones.</p>    
        </div>
          
        
          <form className="feed__content-form" onSubmit={handleFinderSubmit} >
            <input type="text" id="departure" name="departure" value={finderFormData.departure} onChange={handleFinderChange} placeholder="From (e.g. Islamabad)" inputMode="text"/>
            <input type="text" id="arrival" name="arrival" value={finderFormData.arrival} onChange={handleFinderChange} placeholder="To (e.g. Lahore)" inputMode="text"/>
              
              
              
            {/*<input type="text" id="route" name="route" value={finderFormData.route} onChange={handleFinderChange} placeholder="Via (e.g. M-1)"/>
            
            <input type="datetime-local" id="date" name="estimatedDate" value={finderFormData.estimatedDate} onChange={handleFinderChange}/>*/}
            <button type="submit">Find</button>
            {/*<button onClick={handleClearFilter}>Reset</button>*/}
          </form>
        
        
        <div className="feed__content-rides">
          <p className="feed__content-rides__error">{finderError}</p>
          {
            feedDetails.map((element) => {
              const { _id, user, arrival, departure, route, estimatedDate} = element;
              
              let date = new Date(estimatedDate);
              const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

              if (Math.abs(date - Date.now()) < oneDayInMs) {
                date = date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}); // Within 24 hours, show time
              } else {
                date = date.toLocaleDateString(); // More than 24 hours away, show date
              }
              
              return (
                <div className="feed__content-rides__post">
                  <div onClick={() => handleGetSingleRide(_id)} className="feed__content-rides__post-left">
                    <h2><FontAwesomeIcon icon={faPlaneDeparture} /> {departure} </h2>
                    <h2><FontAwesomeIcon icon={faPlaneArrival} /> {arrival}</h2>
                    <p><FontAwesomeIcon icon={faRoute} /> {route.length > 18 ? `${route.substring(0, 15)}...` : route}</p>
                    <p>
                      <FontAwesomeIcon icon={faCalendar} />
                      {date}
                      {/*new Date(estimatedDate).toLocaleDateString()}
                      <span>{new Date(estimatedDate).toLocaleTimeString()</span>*/}
                    </p>
                  </div>
                  
                  <div className="feed__content-rides__post-right">
                    <Link to={`/profile/${user._id}`}><img src={user.avatar} alt="userimage" /></Link>
                    <p><Link to={`/profile/${user._id}`}>{user.userName.split(' ')[0]}</Link></p>
                  </div>

                  
                  {/*<Field heading="From" text={departure}></Field>
                  <Field heading="To" text={arrival}></Field>
                  <Field heading={dateTime} text=></Field>
                  <Link to={`/profile/${_id}`}><Field heading="Posted by" text={user.userName}></Field></Link>*/}
                </div>
              );
            })
          }      
        </div>
      </div>

      
    </div>
  );
}

export default Feed;
