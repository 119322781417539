import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Publish = ( { isLoggedIn, loginUser, onLogout } ) => {
  const [cities, setCities] = useState(["New York", "Paris", "Tokyo", "London", "Sydney"]);
  const [finderFormData, setFinderFormData] = useState({departure: cities[0], arrival: cities[1], route:"", estimatedDate: formatDate(new Date()) });
  const [posterFormData, setPosterFormData] = useState({departure: cities[0], arrival: cities[1], route:"", estimatedDate: formatDate(new Date()), totalSeats: "", fare: { amount: "500", currency: "PKR" } });
  const [finderError, setFinderError] = useState("");
  const [posterError, setPosterError] = useState("");
  const [isPosterMode, setIsPosterMode] = useState(false);
  const navigate = useNavigate();
  
  useEffect(()=> {
    const defaultCities = /*loginUser.country !== "Pakistan" 
      ? ["New York", "Paris", "Tokyo", "London", "Sydney"] 
      :*/ ["Islamabad", "Rawalpindi", "Kamra", "Lahore", "Multan", "Peshawar"];
      
    setCities(defaultCities);
    setFinderFormData((prevFormData) => ({ ...prevFormData, departure: defaultCities[0], arrival: defaultCities[1] }));
    setPosterFormData((prevFormData) => ({ ...prevFormData, departure: defaultCities[0], arrival: defaultCities[1], fare: { amount: loginUser.country !== "Pakistan" ? "15" : "500", currency: loginUser.country !== "Pakistan" ? "USD" : "PKR", } }));
  }, [loginUser]);
  
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  // Helper function to format the date
  function formatDate(date) {
    const pad = (num) => String(num).padStart(2, '0');
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours()+1)}:${pad(date.getMinutes())}`;
  }

  const handleFinderChange = (event) => {
    setFinderError("");

    const { name, value } = event.target;
    setFinderFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleRequestSubmit = async (event) => {
    event.preventDefault();
    setFinderError("");

    try {
      const response = await axios.post('/api/v1/ride/createRequest/', finderFormData);
      
      setFinderFormData({departure: cities[0], arrival: cities[1], route:"", estimatedDate: formatDate(new Date()) });
      setFinderError(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else {
        setFinderError(error.response.data.message);      
      }
    }
  };
  
  const handlePosterClick = () => {
    setIsPosterMode(true); // Set the state to true when the user clicks Sign Up
    setFinderError("");
  };

  const handleFinderClick = () => {
    setIsPosterMode(false); // Set the state to false when the user clicks Finder
    setPosterError("");
  };

  /****************** */
  const handlePosterChange = (event) => {
    setPosterError(''); // Reset error message

    const { name, value } = event.target;
    setPosterFormData((prevData) => {
      if (name === 'amount') {
        return {
          ...prevData,
          fare: {
            ...prevData.fare,
            amount: value ? parseInt(value) : ''
          }
        };
      } else {
        return {
          ...prevData,
          [name]: value
        };
      }
    });
  };

  const handlePosterSubmit = async (event) => {
    event.preventDefault();
    setPosterError("");

    try {
      const response = await axios.post(`/api/v1/ride/createRide`, posterFormData);
      
      setPosterFormData({departure: cities[0], arrival: cities[1], route:"", estimatedDate: formatDate(new Date()), totalSeats: "" });
      setPosterError(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        //console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else {
        setPosterError(error.response.data.message);
        //console.log('An error occurred while posting the ride');
      }
    }
  };
  
  return (
    <div className="login__form section__padding gradient__bg">

        <div className="login__form-content">
          <div className="login__form-content__heading">
            <div className={`login__form-content__heading-container ${isPosterMode ? 'shift-right' : ''}`}>
            
              <div className="login__form-content__heading-container__wrapper">
                <h1 className={`gradient__text ${isPosterMode ? 'hide' : ''}`}>Offer Ride!</h1>
                <p className={`${isPosterMode ? 'hide' : ''}`}>I'm traveling and have a few seats available</p>
              </div>
            
              <div className="login__form-content__heading-container__wrapper">
                <h1 className={`gradient__text ${isPosterMode ? '' : 'hide'}`}>Make Request</h1>
                <p className={`${isPosterMode ? '' : 'hide'}`}>I need a seat and would love to join you</p>
              </div>
            
            </div>        
          </div>
          
          <div className="login__form-content__input">
            <div className="login__form-content__input-slide-controls">
              <input type="radio" name="slide" id="finder" checked={!isPosterMode} onChange={handleFinderClick} />
              <input type="radio" name="slide" id="poster" checked={isPosterMode} onChange={handlePosterClick} />
              <label htmlFor="finder" className={`slide`} onClick={handleFinderClick}> Offer </label>
              <label htmlFor="poster" className={`slide`} onClick={handlePosterClick}> Request </label>
              <div className={`slider-tab ${isPosterMode ? 'shift-right-slider' : ''}`}></div>
            </div>

            <div className={`login__form-content__input-container ${isPosterMode ? 'shift-right' : ''}`}>
              {/* Poster Form */}
              <div className="login__form-content__input-container__wrapper">
                <form onSubmit={handlePosterSubmit} className={`${isPosterMode ? 'hide' : ''}`} >
                  <div>                  
                    <label htmlFor="departure">
                      From
                      <select id="departure" name="departure" value={posterFormData.departure} onChange={handlePosterChange}>
                      {cities.map((city) => (
                        <option value={city}>{city}</option>
                      ))}
                      </select>
                    </label>
                    <label htmlFor="arrival">
                      To
                      <select id="arrival" name="arrival" value={posterFormData.arrival} onChange={handlePosterChange}>
                      {cities.map((city) => (
                        <option value={city}>{city}</option>
                      ))}
                      </select>
                    </label>
                  </div>
                  
                  <label htmlFor="route">
                    Via
                    <input type="text" id="route" name="route" value={posterFormData.route} onChange={handlePosterChange} placeholder="Highway M-1"/>
                  </label>
                  <label htmlFor="estimatedDate">
                    ETD
                    <input type="datetime-local" id="date" name="estimatedDate" value={posterFormData.estimatedDate} onChange={handlePosterChange}/>
                  </label>
                  
                  <div>
                    <label htmlFor="amount">
                      Fare ({posterFormData.fare.currency})
                      <input type="number" id="amount" name="amount" min={1} value={posterFormData.fare.amount} onChange={handlePosterChange} placeholder="500"/>
                    </label>
                    <label htmlFor="totalSeats">
                      Available Seats
                      <input type="number" id="totalSeats" name="totalSeats" min={1} max={6} value={posterFormData.totalSeats} onChange={handlePosterChange} placeholder="1"/>
                    </label>
                  </div>
                  
                  {posterError !== '' && <p className="error">{posterError}</p>}

                  <button type="submit">Offer Ride</button>
                  
                </form>
              </div>            

              {/* Finder Form */}
              <div className="login__form-content__input-container__wrapper">
                <form onSubmit={handleRequestSubmit} className={`${isPosterMode ? '' : 'hide'}`} >
                  <div>  
                    <label htmlFor="departure">
                      From
                      <select id="departure" name="departure" value={finderFormData.departure} onChange={handleFinderChange}>
                      {cities.map((city) => (
                        <option value={city}>{city}</option>
                      ))}
                      </select>
                    </label>                  
                    <label htmlFor="arrival">
                      To
                      <select id="arrival" name="arrival" value={finderFormData.arrival} onChange={handleFinderChange}>
                      {cities.map((city) => (
                        <option value={city}>{city}</option>
                      ))}
                      </select>
                    </label>
                  </div>
                  <label htmlFor="route">
                    Via
                    <input type="text" id="route" name="route" value={finderFormData.route} onChange={handleFinderChange} placeholder="Highway M-1"/>
                  </label>
                  <label htmlFor="estimatedDate">
                    ETD
                    <input type="datetime-local" id="date" name="estimatedDate" value={finderFormData.estimatedDate} onChange={handleFinderChange}/>
                  </label>
                  
                  {finderError !== '' && <p className="error">{finderError}</p>}

                  <button type="submit">Request Ride</button>
                </form>
              </div>
              
            </div>
          </div>
        </div>
    </div>
  );
}

export default Publish;