import React, { useState, useEffect } from 'react';

import { Home, Footer, Feed, Publish, Profile, Listing, Faqs } from "./containers";
import { Navbar, Login, Message, Ride, Admin } from "./components";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import './App.css';

const App = () => {
  const location = useLocation(); // Get the current location

  // Scroll to top whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // Dependency array includes location

  const [loginUser, setLoginUser] = useState(() => {
    try {
      const storedUser = localStorage.getItem('loginUser');
      return storedUser && storedUser !== "undefined" ? JSON.parse(storedUser) : {}; // Ensure it's parsed correctly
    } catch (e) {
      console.error("Failed to parse loginUser from localStorage", e);
      return {}; // Default to an empty object in case of error
    }
  });

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedUser = localStorage.getItem('loginUser');
    return storedUser && Object.keys(JSON.parse(storedUser || "{}")).length > 0;
  });
  
  const handleLogin = (user) => {
    if (user && typeof user === 'object' && Object.keys(user).length > 0) {
      setLoginUser(user);
      localStorage.setItem('loginUser', JSON.stringify(user));
    }
  };

  // Function to handle logout
  const handleLogout = () => {
    setLoginUser({});
    localStorage.removeItem('loginUser');
  };

  // Watch for changes in loginUser to update isLoggedIn
  useEffect(() => {
    setIsLoggedIn(loginUser && Object.keys(loginUser).length > 0);
  }, [loginUser]);

  // Listen to changes in localStorage from other tabs or actions
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'loginUser') {
        const storedUser = localStorage.getItem('loginUser');
        
        if (storedUser === "undefined") {
          localStorage.removeItem('loginUser');
          setLoginUser({});
        }
        else
          setLoginUser(storedUser ? JSON.parse(storedUser) : {});
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout} ></Navbar>
      <Routes className='App'>
        <Route path="/" element={<Home isLoggedIn={isLoggedIn} />}/>
        <Route path="/login" element={<Login isLoggedIn={isLoggedIn} onLogin={handleLogin} />}/>
        <Route path="/message" element={<Message />}/>
        <Route path="/feed" element={<Feed isLoggedIn={isLoggedIn} loginUser={loginUser} onLogout={handleLogout} />}/>
        <Route path="/publish" element={<Publish isLoggedIn={isLoggedIn} loginUser={loginUser} onLogout={handleLogout} />}/>
        <Route path="/profile/:profileId?" element={<Profile isLoggedIn={isLoggedIn} loginUser={loginUser} onLogout={handleLogout} />}/>
        <Route path="/listing/:profileId?" element={<Listing isLoggedIn={isLoggedIn} loginUser={loginUser} onLogout={handleLogout} />}/>
        <Route path="/ride/:rideId" element={<Ride isLoggedIn={isLoggedIn} loginUser={loginUser} onLogout={handleLogout} />}/>
        <Route path="/admin" element={<Admin isLoggedIn={isLoggedIn} loginUser={loginUser} onLogout={handleLogout} />}/>
        <Route path="/faqs" element={<Faqs />}/>
      </Routes>      
      <Footer></Footer>
    </>
  );
};

// Wrap the App component in BrowserRouter
const WrappedApp = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default WrappedApp;